import AppPaths from '../../all/AppPaths';
import { pick } from 'lodash';

export default pick(
  AppPaths,
  'login',
  'home',
  'profile',
  'changePassword',
  'minute',
);
