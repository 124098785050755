import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";

import AuthInterceptor from "services/AuthInterceptor";

import { Login } from "Routes";

import PageLoader from "components/Common/Loaders";
import Base from "components/Layout/Base";

import StorageAuth from "services/storage/Auth";

// Role Related
import AppPaths from "./AppPaths";
import MetaMenu from "./MetaMenu";
import ApiAuthPaths from "./ApiAuthPaths";

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;

AuthInterceptor(ApiAuthPaths);

const Home = lazy(() => import("pages/Home/Home"));
const Users = lazy(() => import("pages/Users/Users"));
const Categories = lazy(() => import("pages/Categories/Categories"));
const CategoryDetails = lazy(() => import("pages/CategoryDetails/CategoryDetails"));
const References = lazy(() => import("pages/References/References"));
const ReferenceDetails = lazy(() => import("pages/ReferenceDetails/ReferenceDetails"));
const Suppliers = lazy(() => import("pages/Suppliers/Suppliers"));
const Centers = lazy(() => import("pages/Centers/Centers"));
const CenterDetails = lazy(() => import("pages/CenterDetails/CenterDetails"));
const SupplierDetails = lazy(() => import("pages/SupplierDetails/SupplierDetails"));
const Budgets = lazy(() => import("pages/Budgets/Budgets"));
const BudgetDetails = lazy(() => import("pages/BudgetDetails/BudgetDetails"));
const UserProfile = lazy(() => import("pages/UserDetails/UserProfile/UserProfile"));
const ChangePassword = lazy(() => import("pages/UserDetails/ChangePassword/ChangePasword"));
const Models = lazy(() => import("pages/Models/Models"));
const ModelDetails = lazy(() => import("pages/ModelDetails/ModelDetails"));
const Orders = lazy(() => import("pages/Orders/Orders"));
const OrderDetails = lazy(() => import("pages/OrderDetails/OrderDetails"));
const Taxes = lazy(() => import("pages/Taxes/Taxes"));
const CenterGroups = lazy(() => import("pages/CenterGroups/CenterGroups"));
const Campaigns = lazy(() => import("pages/Campaigns/Campaigns"));
const Requests = lazy(() => import("pages/Requests/Requests"));
const RequestDetails = lazy(() => import("pages/RequestDetails/RequestDetails"));
const Brands = lazy(() => import("pages/Brands/Brands"));
const ProviderEntities = lazy(() => import("pages/ProviderEntities/ProviderEntities"));
const BudgetExercises = lazy(() => import("pages/BudgetExercises/BudgetExercises"));
const BudgetRequestDetails = lazy(() => import("pages/BudgetRequestDetails/BudgetRequestDetails"));
const BudgetRequests = lazy(() => import("pages/BudgetRequests/BudgetRequests"));
const Comparator = lazy(() => import("pages/Comparator/Comparator"));
const Stock = lazy(() => import("pages/Stock/Stock"));
const StockArrivals = lazy(() => import("pages/StockArrivals/StockArrivals"));
const ArrivalsDetails = lazy(() => import("pages/Arrivals/ArrivalDetails"));
const Deliveries = lazy(() => import("pages/Deliveries/Deliveries"));
const DeliveryDetails = lazy(() => import("pages/Delivery/DeliveryDetails"));
const ExpedientTypes = lazy(() => import("pages/ExpedientTypes/ExpedientTypes"));
const ExecutionPlans = lazy(() => import("pages/ExecutionPlan/ExecutionPlan"));
const Inventories = lazy(() => import("pages/Inventories/Inventories"));
const Agreements = lazy(() => import("pages/Agreement/Agreement"));
const AgreementDetails = lazy(() => import("pages/AgreementDetail/AgreementDetails"));
const LotDetails = lazy(() => import("pages/LotDetail/LotDetails"));
const Basats = lazy(() => import("pages/Basats/Basats"));
const BasatDetail = lazy(() => import("pages/BasatDetail/BasatDetails"));
const Minutes = lazy(() => import("pages/Minutes/List/List"));
const MinutesDetail = lazy(() => import("pages/Minutes/Detail/Detail"));

export default ({ location }: RouteProps) => {
	const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession());

	if (!isLoggedIn) return <Login />;
	return (
		<Base menu={MetaMenu}>
			<div>
				<Suspense fallback={<PageLoader />}>
					<Switch location={location}>
						<Route path={AppPaths.home} component={waitFor(Home)} />
						<Route path={AppPaths.user.list} exact component={waitFor(Users)} />
						<Route path={AppPaths.category.list} exact component={waitFor(Categories)} />
						<Route path={AppPaths.category.detail} component={waitFor(CategoryDetails)} />
						<Route path={AppPaths.supplier.list} exact component={waitFor(Suppliers)} />
						<Route path={AppPaths.supplier.detail} component={waitFor(SupplierDetails)} />
						<Route path={AppPaths.reference.list} exact component={waitFor(References)} />
						<Route path={AppPaths.reference.detail} component={waitFor(ReferenceDetails)} />
						<Route path={AppPaths.budget.list} exact component={waitFor(Budgets)} />
						<Route path={AppPaths.budget.new} exact component={waitFor(BudgetDetails)} />
						<Route path={AppPaths.budget.detail} component={waitFor(BudgetDetails)} />
						<Route path={AppPaths.center.list} exact component={waitFor(Centers)} />
						<Route path={AppPaths.center.detail} component={waitFor(CenterDetails)} />
						<Route path={AppPaths.reference.list} exact component={waitFor(References)} />
						<Route path={AppPaths.reference.detail} component={waitFor(ReferenceDetails)} />
						<Route path={AppPaths.model.list} exact component={waitFor(Models)} />
						<Route path={AppPaths.model.detail} component={waitFor(ModelDetails)} />
						<Route path={AppPaths.order.list} exact component={waitFor(Orders)} />
						<Route path={AppPaths.order.new} exact component={waitFor(OrderDetails)} />
						<Route path={AppPaths.order.detail} component={waitFor(OrderDetails)} />
						<Route path={AppPaths.tax.list} exact component={waitFor(Taxes)} />
						<Route path={AppPaths.centergroup.list} exact component={waitFor(CenterGroups)} />
						<Route path={AppPaths.campaign.list} exact component={waitFor(Campaigns)} />
						<Route path={AppPaths.request.list} exact component={waitFor(Requests)} />
						<Route path={AppPaths.request.new} exact component={waitFor(RequestDetails)} />
						<Route path={AppPaths.request.detail} exact component={waitFor(RequestDetails)} />
						<Route path={AppPaths.brand.list} exact component={waitFor(Brands)} />
						<Route path={AppPaths.providerEntity.list} exact component={waitFor(ProviderEntities)} />
						<Route path={AppPaths.budgetExercise.list} exact component={waitFor(BudgetExercises)} />
						<Route path={AppPaths.budgetRequest.list} exact component={waitFor(BudgetRequests)} />
						<Route path={AppPaths.budgetRequest.new} exact component={waitFor(BudgetRequestDetails)} />
						<Route path={AppPaths.budgetRequest.detail} exact component={waitFor(BudgetRequestDetails)} />
						<Route path={AppPaths.comparator.list} exact component={waitFor(Comparator)} />
						<Route path={AppPaths.stock.list} exact component={waitFor(Stock)} />
						<Route path={AppPaths.arrivals.new} exact component={waitFor(ArrivalsDetails)} />
						<Route path={AppPaths.arrivals.list} exact component={waitFor(StockArrivals)} />
						<Route path={AppPaths.arrivals.detail} exact component={waitFor(ArrivalsDetails)} />
						<Route path={AppPaths.delivery.new} exact component={waitFor(DeliveryDetails)} />
						<Route path={AppPaths.delivery.list} exact component={waitFor(Deliveries)} />
						<Route path={AppPaths.delivery.detail} exact component={waitFor(DeliveryDetails)} />
						<Route path={AppPaths.expedientTypes.detail} exact component={waitFor(ExpedientTypes)} />
						<Route path={AppPaths.expedientTypes.new} exact component={waitFor(ExpedientTypes)} />
						<Route path={AppPaths.expedientTypes.list} exact component={waitFor(ExpedientTypes)} />
						<Route path={AppPaths.executionPlans.detail} exact component={waitFor(ExecutionPlans)} />
						<Route path={AppPaths.executionPlans.new} exact component={waitFor(ExecutionPlans)} />
						<Route path={AppPaths.executionPlans.list} exact component={waitFor(ExecutionPlans)} />
						<Route path={AppPaths.inventory.detail} exact component={waitFor(Inventories)} />
						<Route path={AppPaths.inventory.new} exact component={waitFor(Inventories)} />
						<Route path={AppPaths.inventory.list} exact component={waitFor(Inventories)} />
						<Route path={AppPaths.agreement.list} exact component={waitFor(Agreements)} />
						<Route path={AppPaths.agreement.new} exact component={waitFor(AgreementDetails)} />
						<Route path={AppPaths.agreement.detail} exact component={waitFor(AgreementDetails)} />
						<Route path={AppPaths.lot.new} exact component={waitFor(LotDetails)} />
						<Route path={AppPaths.lot.detail} exact component={waitFor(LotDetails)} />
						<Route path={AppPaths.basat.list} exact component={waitFor(Basats)} />
						<Route path={AppPaths.basat.new} exact component={waitFor(BasatDetail)} />
						<Route path={AppPaths.basat.detail} exact component={waitFor(BasatDetail)} />
						<Route path={AppPaths.minute.list} exact component={waitFor(Minutes)} />
						<Route path={AppPaths.minute.detail} exact component={waitFor(MinutesDetail)} />

						<Route path="/profile" component={waitFor(UserProfile)} />
						<Route path="/change-password" component={waitFor(ChangePassword)} />
						<Redirect to={AppPaths.home} />
					</Switch>
				</Suspense>
			</div>
		</Base>
	);
};
