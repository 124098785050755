export default [
  '/user',
  '/category',
  '/reference',
  '/product-reference',
  '/campaign',
  '/supplier',
  '/budget',
  '/budget-status',
  '/order-status',
  '/admin/search',
  '/admin/config',
  '/asset',
  '/center',
  '/campaign',
  '/model',
  '/product-reference',
  '/brand',
  '/order',
  '/tax',
  '/order-status',
  '/request',
  '/request-status',
  '/request-approval-status',
  '/expedient-type',
  '/provider-entity',
  '/budget-exercise',
  '/notification-type',
  '/execution-plan',
  '/stock',
  '/arrival',
  '/warehouse',
  '/delivery',
  '/inventory',
  '/job',
  '/dashboard',
  '/agreement',
  '/lot',
  '/basat',
  '/minute'
]
