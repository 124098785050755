import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, RouteProps, withRouter } from "react-router-dom";
import StorageAuth from "services/storage/Auth";

import PageLoader from "components/Common/Loaders";
import BaseEmpty from "components/Layout/BaseEmpty";

import AppPaths from "permissions/roles/all/AppPaths";

import superadmin_RoutesControllers from "permissions/roles/superadmin/config/RoutesControllers";
import admin_RoutesControllers from "permissions/roles/admin/config/RoutesControllers";
import warehouse_RouteControllers from "permissions/roles/warehouse/config/RoutesControllers";
import business_RouteControllers from "permissions/roles/business/config/RoutesControllers";
import center_user_RouteControllers from "permissions/roles/center_user/config/RoutesControllers";
import regional_RouteControllers from "permissions/roles/regional/config/RoutesControllers";
import viewer_RouteControllers from "permissions/roles/viewer/config/RoutesControllers";
import admin_warehouse_RouteControllers from "permissions/roles/admin_warehouse/config/RoutesControllers";
import center_deal_RouteControllers from "permissions/roles/center_deal/config/RoutesControllers";
import economic_team_RouteControllers from "permissions/roles/economic_team/config/RoutesControllers";

const { role }: any = StorageAuth.getSession();

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;

const LoginForm = lazy(() => import("pages/LoginForm/LoginForm"));

export const Login = ({ location }: RouteProps) => {
	return (
		<BaseEmpty>
			<Suspense fallback={<PageLoader />}>
				<Switch location={location}>
					<Route path={AppPaths.login} component={waitFor(LoginForm)} />
					<Redirect to={AppPaths.login} />
				</Switch>
			</Suspense>
		</BaseEmpty>
	);
};

const roleControllers: any = {
	superadmin: superadmin_RoutesControllers,
	admin: admin_RoutesControllers,
	warehouse: warehouse_RouteControllers,
	business: business_RouteControllers,
	center_user: center_user_RouteControllers,
	regional: regional_RouteControllers,
	viewer: viewer_RouteControllers,
	admin_warehouse: admin_warehouse_RouteControllers,
	center_deal: center_deal_RouteControllers,
	economic_team: economic_team_RouteControllers,
};
export default withRouter(roleControllers[role] || Login);
