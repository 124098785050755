// function isUserOfSameHospital(user: any, identity: any, context: any) { return true }

/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list, h: hide
 */
export default  [
  { role: 'center_deal',    target: 'center.list',              permissions: 'r' },
  { role: 'center_deal',    target: 'center.detail',            permissions: 'r' },
  { role: 'center_deal',    target: 'center-group.list',        permissions: 'r' },
  { role: 'center_deal',    target: 'reference.list',           permissions: 'r' },
  { role: 'center_deal',    target: 'reference.detail',         permissions: 'r' },
  { role: 'center_deal',    target: 'model.list',               permissions: 'r' },
  { role: 'center_deal',    target: 'model.detail',             permissions: 'r' },
  { role: 'center_deal',    target: 'campaign.list',            permissions: 'r' },  
  { role: 'center_deal',    target: 'reference.price',          permissions: 'r' },
  { role: 'center_deal',    target: 'model.price',              permissions: 'r' },
  { role: 'center_deal',    target: 'budget.price',             permissions: 'h' },
  { role: 'center_deal',    target: 'order.price',              permissions: 'h' },
  { role: 'center_deal',    target: 'arrival.price',            permissions: 'h' },
  { role: 'center_deal',    target: 'agreement.list',           permissions: 'r' },
  { role: 'center_deal',    target: 'agreement.detail',         permissions: 'r' },
  { role: 'center_deal',    target: 'basat.list',               permissions: 'r' },
  { role: 'center_deal',    target: 'budgetExercise.list',      permissions: 'r' },
  { role: 'center_deal',    target: 'lot.detail',               permissions: 'r' },
  { role: 'center_deal',    target: 'minute.detail',            permissions: 'r' },
  { role: 'center_deal',    target: 'minute.list',              permissions: 'r' },

  // { role: 'center_user',    target: 'request',                   permissions: ['rl-wd', isUserOfSameHospital]}
]
