import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import AuthInterceptor from 'services/AuthInterceptor';

import { Login } from 'Routes';

import PageLoader from 'components/Common/Loaders';
import Base from 'components/Layout/Base';

import StorageAuth from 'services/storage/Auth';

// Role Related
import AppPaths from './AppPaths';
import MetaMenu from './MetaMenu';
import ApiAuthPaths from './ApiAuthPaths';

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />

AuthInterceptor(ApiAuthPaths);

const Home = lazy(() => import('pages/Home/Home'));
const UserProfile = lazy(() => import('pages/UserDetails/UserProfile/UserProfile'));
const ChangePassword = lazy(() => import('pages/UserDetails/ChangePassword/ChangePasword'));
const Minutes = lazy(() => import("pages/Minutes/List/List"));
const MinutesDetail = lazy(() => import("pages/Minutes/Detail/Detail"));

export default ({ location }: RouteProps) => {
  const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession());

  if (!isLoggedIn) return <Login />;

  return (
    <Base menu={MetaMenu}>
      <div>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path={AppPaths.home} component={waitFor(Home)} />
            <Route path={AppPaths.minute.list} exact component={waitFor(Minutes)} />
						<Route path={AppPaths.minute.detail} exact component={waitFor(MinutesDetail)} />

            <Route path="/profile" component={waitFor(UserProfile)} />
            <Route path="/change-password" component={waitFor(ChangePassword)} />
            <Redirect to={AppPaths.home} />
          </Switch>
        </Suspense>
      </div>
    </Base>
  );
}
